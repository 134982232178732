<template>
    <div class="sideScreen" v-if="show === true">
        <b-button @click="test">getMediahub</b-button>
        <b-button @click="test2">getEdgeDevices</b-button>
        <b-button @click="test3">getEdgeDeviceListeners</b-button>
    </div>
</template>
<script>
import deviceStore from "@/store/deviceStore";
import authStore from "@/store/authStore";
import gammaStore from "@/store/gammaStore";
export default {
    name: "GlobalTest",
    data() {
        return {
            show: false
        }
    },
    methods: {
        async test() {
            console.log(await gammaStore.getters.getAllHubs);
        },
        async test2() {
            console.log(await gammaStore.dispatch('closeActiveHubs'));
        },
        async test3() {
            console.log(await deviceStore.getters.getDeviceStore);
        },
    },
    computed: {
        currentCameras() {
            return deviceStore.getters.getCurrentCameras;
        },
        accessToken() {
            return authStore.getters.getUserManager.getAccessToken();
        }
    }
}
</script>
<style scoped>
.sideScreen {
    position: absolute;
    z-index: 999999999;
    left: 50%;
    top: 5%;
}
</style>
