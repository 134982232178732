<template>
    <div :class="{dark: !loaded && cubes, darker:!loaded && !cubes}" class="app">
        <GlobalTest/>
        <div v-if="!loaded" style="width: 100%;height: 100%;position: absolute;z-index : 2000;" class="m-0"
             :class="{'sk-wandering-cubes': cubes}">
            <div v-if="cubes">
                <div style="left:50%; top:40%" class="sk-cube sk-cube1"></div>
                <div style="left:50%; top:40%" class="sk-cube sk-cube2"></div>
            </div>
        </div>
        <AppHeader fixed>
            <SidebarToggler class="d-lg-none" display="md" mobile/>
            <b-link class="navbar-brand" to="/dashboard">
                <img class="navbar-brand-full" :src="logo" width="100%" style="margin-left: 10%; padding-right: 5%" alt="">
                <img class="navbar-brand-minimized" :src="logoSm" width="100%" style="padding: 8px" alt="">
            </b-link>
            <SidebarToggler class="d-md-down-none" display="lg"/>
            <b-navbar-nav class="ml-auto">
                <b-nav-item class="d-md-down-none">
                    <DefaultHeaderDropdownNotif/>
                </b-nav-item>
                <!--<b-nav-item class="d-md-down-none">
                  <DefaultHeaderDropdownTasks/>
                </b-nav-item>
                <b-nav-item class="d-md-down-none">
                  <DefaultHeaderDropdownMssgs/>
                </b-nav-item>
                <b-nav-item class="d-md-down-none">
                  <DefaultHeaderDropdown/>
                </b-nav-item>-->
                <DefaultHeaderDropdownAccnt/>
            </b-navbar-nav>
            <!--<AsideToggler class="d-none d-lg-block" />-->
            <!--<AsideToggler class="d-lg-none" mobile />-->
        </AppHeader>
        <div class="app-body">
            <AppSidebar fixed id="default_container_sidebar">
                <SidebarHeader/>
                <SidebarForm/>
                <SidebarNav :navItems="nav" :key="'sidebarNav'+videoWallIter" :isVideoWall="isVideoWall"/>
                <SidebarFooter/>
                <SidebarMinimizer/>
            </AppSidebar>
            <main class="main" v-touch="touchHandler" v-touch:swipe.right="swipeRight">
                <Breadcrumb v-if="isMobile()" :list="list" style="margin-bottom: 0"/>
                <Breadcrumb v-else :list="list"/>
                <router-view v-if="isMobile()" @loading="loaded=false" @loaded="loaded=true"
                             @navUpdate="navUpdate" ref="videoWall"/>
                <div v-else class="container-fluid">
                    <router-view @loading="loaded=false" @loaded="loaded=true"
                                 @navUpdate="navUpdate" ref="videoWall"/>
                </div>
            </main>
            <!--<AppAside fixed>
                    &lt;!&ndash;aside&ndash;&gt;
                    <DefaultAside/>
            </AppAside>-->
        </div>
        <TheFooter v-if="isMobile()" style="display: block">
            <p style="text-align: center">
                <a href="#">MTOP Satellite</a>
                &copy; 2025 MTOP
                <br>
                Powered by
                <a href="http://monteltech.com">MTOP Enterprise</a></p>
        </TheFooter>
        <TheFooter v-else>
            <!--footer-->
            <div>
                <a href="#">MTOP Satellite</a>
                <span class="ml-1">&copy; 2025 MTOP</span>
            </div>
            <div class="ml-auto">
                <span class="mr-1">Powered by</span>
                <a href="http://monteltech.com">MTOP Enterprise</a>
            </div>
        </TheFooter>
    </div>
</template>

<script>
import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
import {
    Aside as AppAside,
    AsideToggler,
    Breadcrumb,
    Footer as TheFooter,
    Header as AppHeader,
    Sidebar as AppSidebar,
    SidebarFooter,
    SidebarForm,
    SidebarHeader,
    SidebarMinimizer,
    SidebarNav,
    SidebarToggler
} from '../views/template_files/navbarJazz';
import DefaultAside from './DefaultAside';
import DefaultHeaderDropdown from './DefaultHeaderDropdown';
import DefaultHeaderDropdownNotif from './DefaultHeaderDropdownNotif';
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt';
import DefaultHeaderDropdownMssgs from './DefaultHeaderDropdownMssgs';
import DefaultHeaderDropdownTasks from './DefaultHeaderDropdownTasks';
import tenantStore from "@/store/tenantStore";
import navbarStore from "@/store/navbarStore";
import gammaStore from "@/store/gammaStore";
import deviceStore from "@/store/deviceStore";
import PubSub from 'pubsub-js';
import GlobalTest from "@/components/GlobalTest.vue";

Vue.use(Vue2TouchEvents, {
    touchClass: '',
    tapTolerance: 10,
    swipeTolerance: 200,
    longTapTimeInterval: 400
})

export default {
    name: 'DefaultContainer',
    components: {
        AsideToggler,
        AppHeader,
        AppSidebar,
        AppAside,
        TheFooter,
        Breadcrumb,
        DefaultAside,
        DefaultHeaderDropdown,
        DefaultHeaderDropdownMssgs,
        DefaultHeaderDropdownNotif,
        DefaultHeaderDropdownTasks,
        DefaultHeaderDropdownAccnt,
        SidebarForm,
        SidebarFooter,
        SidebarToggler,
        SidebarHeader,
        SidebarNav,
        SidebarMinimizer,
        GlobalTest
    },
    data() {
        return {
            pubsubs: {},
            userName: null,
            cubes: true,
            loaded: true,
            logo: '/img/mtoplogo.png',
            logoSm: '/img/mtoplogosmall.png',
            url: "",
            nav: [{
                title: true,
                name: 'MTOP Menu Items',
                class: '',
                wrapper: {
                    element: '',
                    attributes: {}
                }
            }],
            devices: null,
            videoWallIter: 0,
            isVideoWall: false
        }
    },
    async beforeRouteUpdate(to, from, next) {
        // This code will be executed every time the route changes
        await gammaStore.dispatch('closeActiveHubs');
        await gammaStore.dispatch('closeActiveListeners');
        if (to.name === 'Video Wall') {
            if (document.body.classList.contains('brand-minimized')) {
                document.body.classList.toggle('brand-minimized')
            }
            if (document.body.classList.contains('sidebar-minimized')) {
                document.body.classList.toggle('sidebar-minimized')
            }
            await this.setUpVideoWall();
            this.videoWallIter++;
            this.isVideoWall = true;
        } else if (from.name === 'Video Wall') {
            if (!document.body.classList.contains('brand-minimized')) {
                document.body.classList.toggle('brand-minimized')
            }
            if (!document.body.classList.contains('sidebar-minimized')) {
                document.body.classList.toggle('sidebar-minimized')
            }
            this.videoWallIter++;
            this.isVideoWall = false;
        }
        next();
    },
    updated() {
        const sidebar = document.querySelector('.sidebar-nav section')
        if (sidebar.classList.contains('ps')) {
            sidebar.classList.remove('ps');
        }
    },
    created() {
        //call that gets ordered ids
        navbarStore.dispatch('getNavInfo').then(navbars => {
            this.nav = this.nav.concat(navbars);
            this.url = JSON.stringify(window.location.href);
            // If on the Video Wall page
            if (this.url.includes("video-wall")) {
                this.setUpVideoWall();
            }
        });
    },
    beforeDestroy() {
        Object.values(this.pubsubs).forEach(pubsub => {
            PubSub.unsubscribe(pubsub);
        });
    },
    computed: {
        list() {
            return this.$route.matched.filter((route) => route.name || route.meta.label)
        }
    },
    methods: {
        setUpVideoWall() {
            this.isVideoWall = true;
            // Find The Video Wall Nav Tab Index
            let videoWallIndex = this.nav.findIndex(_ => _.name === "Video Wall")
            if (~videoWallIndex) {  // If Video Wall tab found

                // Move Video Wall tab to the top
                let tab = this.nav[videoWallIndex]
                this.nav.splice(1, 0, tab);
                this.nav.splice(videoWallIndex + 1, 1);
                tab.children.unshift({form: true});
                //Get the edge services set up for the video wall
                // Getting Tenants and their Devices to display on the sidebar
                tenantStore.dispatch('getTenants').then(tenants => {
                    tenants.forEach(tenant => {
                        deviceStore.dispatch('getEdgeDevices', tenant);
                    });
                    this.setupSidebar(tab, tenants);
                    if (!this.pubsubs.updatetenants) {
                        this.pubsubs.updatetenants = PubSub.subscribe('updatetenants', (msg, data) => {
                            this.updateTenants(tab, data);
                        });
                    }
                });
            }
        },
        async setupSidebar(tab, tenants) {
            let new_consts = tenants.map((tenant) => {
                tenant = JSON.parse(JSON.stringify(tenant));
                tenant.children = [];
                tenant.purpose = 'loading';
                setTimeout(() => {
                    if (tenant.purpose === 'loading') {
                        tenant.purpose = 'videoWallNoCameras'
                    }
                }, 3000);
                tenant.name = tenant._id;
                return tenant;
            });
            tab.children = [{form: true}, ...new_consts];
            tab.children.sort(this.compareTenants);
        },
        async updateTenants(tab, tenants) {
            tab.children.forEach(tenant => {
                if (tenant.form === undefined) {
                    let cur_tenant = tenants.find(_ => _._id === tenant._id);
                    tenant = Object.assign(tenant, cur_tenant);
                    //tenant.name = cur_tenant.tenantname;
                }
            });
        },
        navUpdate(navIds) {
            let body = this.nav.slice(1);
            body.sort(function (a, b) {
                return navIds.indexOf(a._id) - navIds.indexOf(b._id);
            });
            body.unshift(this.nav[0])
            this.nav = body;
        },
        swipeRight() {
            if (screen.width < 992) {
                document.body.classList.add('sidebar-show')
            }
        },
        touchHandler() {
            if (screen.width < 992) {
                document.body.classList.remove('sidebar-show')
            }
        },
        compareTenants(a, b) {
            if (a.form) {
                return -1;
            } else if (b.form) {
                return 1;
            }
            a.name = a.name.toUpperCase();
            b.name = b.name.toUpperCase();
            if (a.name < b.name) {
                return -1;
            } else {
                return 1;
            }
        },
        isMobile() {
            //return((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)) && !JSON.stringify(window.location.href).includes("video-wall");
            //the sidebar defines mobile as having a width of less than 992 px
            return (screen.width < 992) && !JSON.stringify(window.location.href).includes("video-wall");
        },
    }
}
</script>

<style src="spinkit/spinkit.css" lang="css"/>

<style scoped>
.sk-cube {
    background-color: #f3f4f5;
}

.dark {
    opacity: .5;
}

.darker {
    opacity: .1;
}
</style>

